$( document ).on('turbolinks:load', function() {
  if(($('body').hasClass('whatsapp_instances-show') && $('#whatsapp_connected').val() == 'false') || ($('body').hasClass('whatsapp_instances-check_instace_external') && $('#whatsapp_connected').val() == 'false')){
    setInterval(() => {
      $.ajax({
        url: "/check_whatsapp_instance?whatsapp_instance_id=" + $("#whatsapp_instance_id").val(),
        cache: false
      })
      .done(function( html ) {
        console.log(html)
        if(html.status){
          location.reload();
        }
      });
    }, "30000")
  }
})

window.confirmResendNumber = function(number, url, instance_id) {
  Swal.fire({
    title: 'Resend',
    input: 'text',
    showCancelButton: true,
    confirmButtonText: 'Resend',
    showLoaderOnConfirm: true,
    inputValue: number
  }).then((result) => {
    console.log(result)
    if (result.isConfirmed) {
      if(url.includes("locale")){
        link = url + '&retry_9=' + instance_id + '&number=' + result.value
      }else{
        link = url + '?retry_9=' + instance_id + '&number=' + result.value
      }
      window.location = link
    }
  })
}
