$( document ).on('turbolinks:load', function() {
  if($('body').hasClass('plans-index') && $('#monthly_payment_ok').val() == 'false'){
    setInterval(() => {
      $.ajax({
        url: "/check_plan",
        cache: false
      })
      .done(function( html ) {
        console.log(html)
        if(html.status){
          location.reload();
        }
      });
    }, "30000")
  }
  var selector = $(".cpf");
  if (selector){
    var im = new Inputmask(["999.999.999-99", "99.999.999.9999/99"]);
    im.mask(selector);
  }
  $('.cpf').on('change', '', function (e) {
    cpf = $(this)
    //console.log($(cpf).val())
    if($(cpf).val() != ''){
      $('.btn_assinar').attr('disabled', false)
    }else{
      $('.btn_assinar').attr('disabled', true)
    }
  });
  if($('body').hasClass('pages-extension') && $('#extension_payment_ok').val() == 'false'){
    setInterval(() => {
      $.ajax({
        url: "/api/v1/valid_extractor_key?key=" + $("#key").val(),
        cache: false
      })
      .done(function( html ) {
        console.log(html)
        if(html.valid){
          location.reload();
        }
      });
    }, "30000")
  };
})
