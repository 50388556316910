window.addEventListener('turbolinks:load', () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const ref = urlParams.get('ref')
  if(ref != null){
    document.cookie = "ref=" + ref + ";domain=.sendfy.app;path=/";
  }
  $('.select-groups').select2({
    closeOnSelect: false
  });
  $('.select').select2({
    templateResult: hideSelected,
  });
  $('.select-tags').select2({
    tags: true
  });
  $('.select-tags-agent').select2({
    tags: true,
    maximumSelectionLength: 1
  });
  $('.select-whatsapp-redirect').select2({
    tags: true
  });
  $("form").submit(function(){
    $('.modal-photo').show()
  });

  const phoneInputField = document.querySelector(".phone");
  if (phoneInputField) {
    const phoneInput = window.intlTelInput(phoneInputField, {
      initialCountry: 'br',
      hiddenInput: "phone",
      nationalMode: true,
      placeholderNumberType: "MOBILE",
      separateDialCode: true,
        utilsScript:
          "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
      });
  }


  var coll = document.getElementsByClassName("collapsible");
  var i;

  for (i = 0; i < coll.length; i++) {
    coll[i].addEventListener("click", function() {
      this.classList.toggle("active");
      var content = this.nextElementSibling;
      if (content.style.display === "block") {
        content.style.display = "none";
      } else {
        content.style.display = "block";
      }
    });
  }

  $(".youtube-video").click(function () {
    let theModal = $(this).data("target"),
    videoSRC = $(this).attr("data-video"),
    videoSRCauto = videoSRC + "?modestbranding=1&rel=0&controls=1&showinfo=0&html5=1&autoplay=1";
    $(theModal + ' iframe').attr('src', videoSRCauto);
    $(theModal).on('hidden.bs.modal', function (e) {
      $(theModal + ' iframe').attr('src', '');
    })
  });

  if($("#first_login").val() == "true"){
    const driver = window.driver;
    const driverObj = driver({
      steps: [
        { element: '#tour', popover: { title: 'Inicie o tour por aqui', description: 'Entenda como a plataforma funciona.', side: "left", align: 'start' }},
        { element: '.menu-components', popover: { title: 'Navegue entre os menus', description: 'Suas funcionalidades do sistema.', side: "rigth", align: 'start' }},
        { element: '.help-button', popover: { title: 'Canal de ajuda', description: 'Temos vídeos ensinando cada funcionalidade.', side: "rigth", align: 'start' }},
        { element: '.cc-15e7', popover: { title: 'Suporte', description: 'Estamos sempre aqui para ajudar.', side: "top", align: 'start' }},
        { popover: { title: 'Isso é tudo', description: 'Qualquer dúvida ou sugestão, pode nos chamar.' } }
      ]
    });
    driverObj.drive();
    $.post("/update_user_first_login", function(data, status){})
  }

  $(".prompt_btn1").click(function () {
    $("#call_agent_log_prompt").html("")
    $("#call_agent_log_prompt").append("Se apresente como Maria e pergunte gentilmente como esta sendo o dia da pessoa que atender." +
      "\n\n" +
      "Você vende um curso online de cuidador de idosos feito por Angelica Pimenta." +
      "\n\n" +
      "Pergunte se a pessoa tem interesse em trabalhar como cuidador de idosos, se ela não tiver interesse agradeça e desligue a ligação." +
      "\n\n" +
      "Se ela tiver interesse em trabalhar, explique mais detalhes sobre o curso" +
      "\n\n" +
      "O curso tem o investimento de R$ 97 reais podendo parcelar no cartão de crédito em até 12 vezes, o curso é valido pelo MÉC do Brasil, isso é um benefício e traz qualidade no curso." +
      "\n\n" +
      "O curso é totalmente online, tem 25 aulas já gravadas e assim que o aluno terminar de assistir todas as aulas ele ja pode emitir o certificado de forma online." +
      "\n\n" +
      "Caso a pessoa tenha interesse no curso, diga o site angelicapimenta.com e la ela faz o pagamento e vai receber todos os detalhes por email."
    )
  });
  $(".prompt_btn2").click(function () {
    $("#call_agent_log_prompt").html("")
    $("#call_agent_log_prompt").append("Se apresente como Maria e pergunte gentilmente como esta sendo o dia da pessoa que atender." +
      "\n\n" +
      "Você é dona de uma agência de marketing digital e quer oferecer serviços como tráfego pago, automação, página de vendas." +
      "\n\n" +
      "Pergunte se a pessoa tem interesse em ter mais visibilidade e mais vendas pois é isso que uma agência de marketing digital pode ajudar." +
      "\n\n" +
      "Se ela tiver interesse em trabalhar com você, diga a ela que você irá chamar ela no Whatsapp e passasr o link para agendarmos uma reunião e você apresentar uma proposta." +
      "\n\n" +
      "Qualquer outra dúvida ou assunto ela pode acessar o site agencianow.com"
    )
  });
})

function hideSelected(value) {
  if (value && !value.selected) {
    return $('<span>' + value.text + '</span>');
  }
}
