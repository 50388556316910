import 'bootstrap'

import jQuery from "jquery"
window.jQuery = jQuery
window.$ = jQuery

import $ from 'jquery';
import select2 from 'select2';
import 'select2/dist/css/select2.css';
import Inputmask from "inputmask";
import "sweetalert2/dist/sweetalert2.min"
window.Swal = require('sweetalert2')

import { driver } from "driver.js";
import "driver.js/dist/driver.css";
window.driver = driver;

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
//require("chart.js")
//require("Chart.extension.js")
require("jquery-scrollLock.min.js")
require("jquery.scrollbar.min.js")
require("argon.js")
require("webhook.js")
require("webhook_notification.js")
require("automation.js")
require("send_whatsapp.js")
require("custom.js")
require("custom_funil.js")
require("campaign.js")
require("whatsapp.js")
require("plan.js")
require("jquery-maskmoney")
require("payment.js")
require("@nathanvda/cocoon")

global.toastr = require("toastr")

require("trix")
require("@rails/actiontext")
require("@nathanvda/cocoon")
